<div class="col-12 col pb20">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <span class="h1 header">
            {{ headingKey | translate }}
        </span>
    </div>
    <div>
        <di-search-bar [show]="headerParams.showSearchBar" [disabled]="statusUserInput$ | async" fxFlex></di-search-bar>

        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <div *ngIf="(selectedIds$ | async).length > 0 || (isImportable$ | async)">
                <button id="di-list-header-action-dropdown" nz-button nz-dropdown [disabled]="statusUserInput$ | async" [nzDropdownMenu]="menu">
                    Actions
                    <i nz-icon nzType="down"></i>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul *ngIf="(statusUserInput$ | async) !== true" nz-menu>
                        <li *ngFor="let act of headerParams.groupActions" nz-menu-item (click)="act.action()">
                            {{ headerParams.resource.name + '.pages.list.groupActions.' + act.label | translate
                            }}<span *ngIf="act.dynamicValue">( {{ act.dynamicValue }} )</span>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <button
                *ngIf="headerParams.showReindex"
                nz-button
                nzType="default"
                class="mx-8 mb-6"
                (click)="reindex()"
                [nzLoading]="reindexing$ | async">
                <i nz-icon nzType="sync" class="mr-2"></i><span>{{ reindexButtonTextKey | translate }}</span>
            </button>

            <button
                *ngIf="headerParams.showAddButton"
                nz-button
                nzType="primary"
                id="di-inline-list-header-add-button"
                class="mx-8 mb-6"
                [nzLoading]="adding"
                [disabled]="statusUserInput$ | async"
                (click)="headerParams.addAction()">
                <i nz-icon nzType="plus" class="mr-2"></i><span>{{ addButtonTextKey | translate }}</span>
            </button>
        </div>
    </div>
</div>
