import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzInputModule} from 'ng-zorro-antd/input';
import {DiSearchBarComponent} from 'app/common/di-search-bar/di-search-bar.component';

@NgModule({
    declarations: [DiSearchBarComponent],
    imports: [CommonModule, TranslateModule, NzIconModule, NzInputModule],
    exports: [DiSearchBarComponent],
    providers: []
})
export class DiSearchBarModule {}
