import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewInit, Input} from '@angular/core';
import {fromEvent, Subject, BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'di-search-bar',
    template: `
        <div class="col-12 row align-center full-height">
            <nz-input-group fxFlex [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixClear" *ngIf="show">
                <input id="search" type="text" nz-input #filter [disabled]="disabled" placeholder="{{ 'common.search' | translate }}" />
            </nz-input-group>
            <ng-template #prefixIconSearch>
                <i nz-icon nzType="search"></i>
            </ng-template>
            <ng-template #suffixClear>
                <a (click)="setSearchTerm('')"><i *ngIf="searchTerm$ | async" nz-icon nzType="close-circle"></i></a>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiSearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('filter')
    private _filter: ElementRef;

    @Input() disabled = false;
    @Input() show = true;

    private _searchTermSubject = new BehaviorSubject('');
    public readonly searchTerm$ = this._searchTermSubject.asObservable();

    private _unsubscribeAll = new Subject<void>();

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this._filter) {
            fromEvent(this._filter.nativeElement, 'keyup')
                .pipe(takeUntil(this._unsubscribeAll), debounceTime(500), distinctUntilChanged())
                .subscribe(() => {
                    const term = this._filter.nativeElement.value;
                    this._searchTermSubject.next(term);
                });
        }
    }

    getCurrentSearchTerm(): string {
        return this._searchTermSubject.getValue();
    }

    setSearchTerm = (term: string): void => {
        if (term !== null) {
            this._filter.nativeElement.value = term;
            this._searchTermSubject.next(term);
        }
    };

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
