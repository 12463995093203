import {CdkTableModule} from '@angular/cdk/table';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '@fuse/components/widget/widget.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzFormModule} from 'ng-zorro-antd/form';

import {DiSearchBarModule} from 'app/common/di-search-bar/di-search-bar.module';
import {MposSearchModule} from 'app/common/mpos-search/mpos-search.module';
import {MposFormsModule} from 'app/common/mpos-forms/mpos-forms.module';
import {DiCreateEditHeaderComponent} from 'app/common/mpos-entity/components/di-create-edit-header/di-create-edit-header.component';
import {DiEntityActionMenuComponent} from 'app/common/mpos-entity/components/di-entity-action-menu/di-entity-action-menu.component';
import {DiInlineListPageHeaderComponent} from 'app/common/mpos-entity/components/di-inline-list-page-header/di-inline-list-page-header.component';
import {DiListPageHeaderComponent} from 'app/common/mpos-entity/components/di-list-page-header/di-list-page-header.component';
import {DiViewHeaderComponent} from 'app/common/mpos-entity/components/di-view-header/di-view-header.component';
import {DiPageTotalItemsModule} from 'app/common/di-page-total-items/di-page-total-items.module';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
    declarations: [
        DiListPageHeaderComponent,
        DiInlineListPageHeaderComponent,
        DiViewHeaderComponent,
        DiCreateEditHeaderComponent,
        DiEntityActionMenuComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,

        CdkTableModule,

        // Angular Material Modules
        MatIconModule,

        // Fuse Modules
        FuseSharedModule,
        FuseWidgetModule,

        // Mpos modules
        MposFormsModule,
        MposSearchModule,

        // For the Ant Design spinner
        NzSpinModule,
        NzAlertModule,

        // New ones for Ant Design
        NzFormModule,
        NzButtonModule,
        NzIconModule,
        NzDropDownModule,
        NzTableModule,
        NzCheckboxModule,
        DiSearchBarModule,
        DiPageTotalItemsModule
    ],
    exports: [
        // Modules
        TranslateModule,
        CdkTableModule,

        // Angular Material Modules

        MatIconModule,

        // Fuse Modules
        FuseSharedModule,
        FuseWidgetModule,

        // Mpos modules
        MposFormsModule,
        MposSearchModule,

        // Ant Design New Ones
        DiListPageHeaderComponent,
        DiInlineListPageHeaderComponent,
        DiViewHeaderComponent,
        DiEntityActionMenuComponent,
        DiSearchBarModule,
        DiCreateEditHeaderComponent,

        NzButtonModule,
        NzIconModule,
        NzDropDownModule,
        NzTableModule,
        NzCheckboxModule,
        DiPageTotalItemsModule
    ],
    providers: []
})
export class MposEntityModule {}
