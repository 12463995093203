<div class="col-12 col">
    <div class="row col-12 align-center">
        <button
            id="button-create-edit-header-back"
            nz-button
            nzType="link"
            [routerLink]="headerParams.resource.url.forList()"
            class="mr-32"
            *ngIf="headerParams.showBack">
            <i nz-icon nzType="arrow-left"></i>
        </button>

        <!-- <div *ngIf="headerParams.resource.icon2" class="mr-16 mr-sm-32 emh125">
        <i nz-icon [nzType]="headerParams.resource.icon2"></i>
    </div> -->

        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="h1 header text-truncate" style="max-width: 1250px">
                {{ heading$ | async }}
            </div>
            <div class="subtitle secondary-text">
                <span>{{ subHeadingKey | translate }}</span>
            </div>
        </div>
    </div>

    <div class="col-12 row justify-end" style="margin-top: -20px">
        <button
            *ngIf="headerParams.showDelete"
            id="button-view-header-delete"
            nz-button
            nzType="default"
            [nzLoading]="false"
            class="mx-24"
            [disabled]="headerParams.deleteDisabled$ | async"
            (click)="headerParams.deleteEntity()">
            <span>{{ deleteButtonTextKey | translate }}</span>
        </button>

        <div class="mx-12" *ngIf="headerParams.showTransaction">
            <button id="di-list-header-new-transaction-dropdown" nz-button nz-dropdown [nzDropdownMenu]="menu">
                New Transaction<i nz-icon nzType="down"></i>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                    <div
                        *ngFor="let act of headerParams.groupTransactions"
                        nz-menu-item
                        [nzDisabled]="(act.enabled$ | async) !== true"
                        [ngClass]="(act.enabled$ | async) !== true ? 'disabled pointer-events-none' : ''"
                        id="{{ 'di-list-header-new-transaction-dropdown-menu-' + act.label }}"
                        (click)="act.action()">
                        {{ headerParams.resource.name + '.pages.view.groupTransactions.' + act.label | translate }}
                        <span *ngIf="act.dynamicValue">( {{ act.dynamicValue }} )</span>
                    </div>
                </ul>
            </nz-dropdown-menu>
        </div>

        <button
            *ngIf="headerParams.showEdit"
            id="button-view-header-edit"
            nz-button
            nzType="primary"
            [nzLoading]="false"
            [disabled]="headerParams.editDisabled$ | async"
            (click)="headerParams.editEntity()">
            <span>{{ editButtonTextKey | translate }}</span>
        </button>
    </div>
</div>
