import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {fromEvent, Subject, BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'mpos-search',
    template: `
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <label for="search" class="mr-8" fxFlex="0 0 auto">
                <mat-icon class="secondary-text">search</mat-icon>
            </label>
            <mat-form-field floatLabel="never" fxFlex="1 0 auto" style="width: 80%">
                <input id="search" matInput #filter placeholder="{{ 'common.search' | translate }}" />
                <button mat-button *ngIf="searchTerm$ | async" matSuffix mat-icon-button aria-label="Clear" (click)="setSearchTerm('')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposSearchComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('filter')
    private _filter: ElementRef;

    private _searchTermSubject = new BehaviorSubject('');
    public readonly searchTerm$ = this._searchTermSubject.asObservable();

    private _unsubscribeAll = new Subject<void>();

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        fromEvent(this._filter.nativeElement, 'keyup')
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(500), distinctUntilChanged())
            .subscribe(() => {
                const term = this._filter.nativeElement.value;
                this._searchTermSubject.next(term);
            });
    }

    getCurrentSearchTerm(): string {
        return this._searchTermSubject.getValue();
    }

    setSearchTerm = (term: string): void => {
        if (term !== null) {
            this._filter.nativeElement.value = term;
            this._searchTermSubject.next(term);
        }
    };

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
