import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MposSearchComponent} from 'app/common/mpos-search/components/search/mpos-search.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [MposSearchComponent],
    imports: [CommonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, TranslateModule],
    exports: [MposSearchComponent],
    providers: []
})
export class MposSearchModule {}
