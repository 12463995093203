<div>
    <!-- <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="default" nzShape="circle"><i nz-icon nzType="more"></i></button> -->
    <a id="{{ 'di-entity-action-dropdown-' + entity.id }}" nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
        <i nz-icon nzType="more"></i
    ></a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <ng-container *ngFor="let act of actions">
                <li
                    id="{{ 'di-entity-action-menu-' + act.label | translate }}"
                    *ngIf="(act.isEnabledFor && act.isEnabledFor(entity)) || !act.isEnabledFor"
                    nz-menu-item
                    (click)="act.action(entity)">
                    {{ resource.name + '.pages.list.rowActions.' + act.label | translate }}
                </li>
            </ng-container>
        </ul>
    </nz-dropdown-menu>
</div>
