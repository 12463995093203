import {Component, Input, OnInit, ChangeDetectionStrategy, HostBinding} from '@angular/core';
import {EntityAction} from 'app/blocks/model/action.model';

@Component({
    selector: 'di-entity-action-menu',
    templateUrl: './di-entity-action-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiEntityActionMenuComponent implements OnInit {
    @Input() entity: any;
    @Input() actions: EntityAction[];
    @Input() resource: any;

    constructor() {}

    ngOnInit(): void {}
}
