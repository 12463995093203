import {SERVER_API_URL} from 'app/app.constants';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ElasticSearchService {
    constructor(private http: HttpClient) {}

    public postElasticSearchReIndexRequest = (entities: string, convertToValidEsIndexName?: boolean): Promise<any> => {
        let REQUEST_URI = `${SERVER_API_URL}api/elasticsearch/re-index?entities=` + entities;
        if (convertToValidEsIndexName) {
            REQUEST_URI = REQUEST_URI + '&convert=true';
        }
        return this.http.post<any>(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    public getIndexes(): Promise<any> {
        const REQUEST_URI = `${SERVER_API_URL}api/elasticsearch/indexes`;
        return this.http.get(REQUEST_URI).toPromise();
    }
}
