<div class="col-12 col pb20">
    <div class="row col-12">
        <!-- <mat-icon class="mr-16 header-icon">
            {{ headerParams.icon }}
        </mat-icon> -->
        <span [ngClass]="isSmallScreen ? 'h5' : 'h1'" class="header">
            {{ headingKey | translate }}
        </span>
    </div>
    <div class="col-12 row space-between">
        <di-search-bar fxFlex [show]="headerParams.showSearchBar"></di-search-bar>

        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <!-- <div *ngIf="(selectedIds$ | async).length > 0"> -->
            <button *ngIf="headerParams.showRefresh" nz-button nzType="primary" class="mx-8 mb-6" (click)="headerParams.refreshAction()">
                <i nz-icon nzType="reload" class="mr-2"></i><span>{{ 'Refresh' }}</span>
            </button>

            <div style="margin-left: 10px" *ngIf="headerParams.showTransaction">
                <button id="di-list-header-new-transaction-dropdown" nz-button nz-dropdown [nzDropdownMenu]="menu">
                    New Transaction<i nz-icon nzType="down"></i>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu style="overflow-y: auto; max-height: 300px">
                        <div
                            *ngFor="let act of headerParams.groupTransactions"
                            nz-menu-item
                            [nzDisabled]="(act.enabled$ | async) !== true"
                            [ngClass]="(act.enabled$ | async) !== true ? 'disabled pointer-events-none' : ''"
                            id="{{ 'di-list-header-new-transaction-dropdown-menu-' + act.label }}"
                            (click)="act.action()">
                            {{ headerParams.resource.name + '.pages.list.groupTransactions.' + act.label | translate }}
                            <span *ngIf="act.dynamicValue">( {{ act.dynamicValue }} )</span>
                        </div>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <div style="margin-right: 10px">
                <span *ngIf="groupActionProcessing$ | async">
                    <nz-spin></nz-spin>
                </span>
            </div>
            <div *ngIf="headerParams.showGroupActions && headerParams.groupActions && headerParams.groupActions.length > 0">
                <button id="di-list-header-action-dropdown" nz-button nz-dropdown [nzDropdownMenu]="menu">
                    Actions<i nz-icon nzType="down"></i>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu style="overflow-y: auto; max-height: 70vh">
                        <li
                            *ngFor="let act of headerParams.groupActions"
                            nz-menu-item
                            [nzDisabled]="(act.enabled$ | async) !== true"
                            [ngClass]="(act.enabled$ | async) !== true ? 'disabled pointer-events-none' : ''"
                            id="{{ 'di-list-header-action-dropdown-menu-' + act.label }}"
                            (click)="act.action()">
                            {{ headerParams.resource.name + '.pages.list.groupActions.' + act.label | translate }}
                            <span *ngIf="act.dynamicValue">( {{ act.dynamicValue }} )</span>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>

            <button
                *ngIf="headerParams.showReindex"
                nz-button
                nzType="default"
                class="mx-8 mb-6"
                (click)="reindex()"
                [nzLoading]="reindexing$ | async">
                <i nz-icon nzType="sync" class="mr-2"></i><span>{{ reindexButtonTextKey | translate }}</span>
            </button>

            <button
                id="button-add-entity"
                *ngIf="headerParams.showAddButton"
                nz-button
                nzType="primary"
                class="mx-8 mb-6"
                (click)="headerParams.addAction()">
                <i nz-icon nzType="plus" class="mr-2"></i><span>{{ addButtonTextKey | translate }}</span>
            </button>
        </div>
    </div>
</div>
