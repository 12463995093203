import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {EntityCreateEditHeaderParams} from 'app/blocks/entity/entity-create-edit-header-params';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {RESPONSIVE_SCREEN_SIZE} from 'app/app.constants';

@Component({
    selector: 'di-create-edit-header',
    styleUrls: ['./di-create-edit-header.component.scss'],
    templateUrl: './di-create-edit-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiCreateEditHeaderComponent implements OnInit, OnDestroy {
    @Input() headerParams: EntityCreateEditHeaderParams;

    private _defaultIcon = 'arrow-left';

    private _editHeadingSubject = new BehaviorSubject('');
    private _unsubscribeAll = new Subject<void>();

    resource: AbstractEntityResource<any>;
    path: string;
    newHeading: string;
    newSubHeading: string;
    addButtonText: string;
    saveButtonText: string;
    addAndApproveButtonText: string;
    saveAndApproveButtonText: string;
    editHeading$: Observable<string> = this._editHeadingSubject.asObservable();
    saving$: Observable<boolean>;
    saveAndApprove$: Observable<boolean>;
    isSmallScreen: boolean;

    constructor(
        private _store: Store,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.resource = this.headerParams.resource;
        this.newHeading = `${this.resource.name}.pages.createEdit.heading`;
        this.newSubHeading = `${this.resource.name}.pages.createEdit.subHeading`;
        this.addButtonText = `${this.resource.name}.pages.createEdit.buttons.add`;
        this.saveButtonText = `${this.resource.name}.pages.createEdit.buttons.save`;
        this.saveAndApproveButtonText = `${this.resource.name}.pages.createEdit.buttons.saveAndApprove`;
        this.addAndApproveButtonText = `${this.resource.name}.pages.createEdit.buttons.addAndApprove`;
        this.path = this.resource.url.forList();
        this.saving$ = this._store.select((state) => state.entities[this.resource.name].entityView.statusSaving);
        this.saveAndApprove$ = this._store.select((state) => state.entities[this.resource.name].entityView.statusSaving);

        if (!this.headerParams.icon) {
            this.headerParams.icon = this._defaultIcon;
        }
        console.log('this.headerParams', this.headerParams);

        this.subscribeToEditHeading();
        this.handleResponsive();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    subscribeToEditHeading(): void {
        this.headerParams.entity$.pipe(takeUntil(this._unsubscribeAll)).subscribe((entity) => {
            if (this.headerParams.editHeadingFn) {
                this._editHeadingSubject.next(this.headerParams.editHeadingFn(entity));
            } else {
                this._editHeadingSubject.next(this.resource.getEntityDiscriminator(entity));
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?): void {
        this.handleResponsive();
        this.changeDetectorRef.detectChanges();
    }

    private handleResponsive(): void {
        if (window.innerWidth <= RESPONSIVE_SCREEN_SIZE) {
            this.isSmallScreen = true;
        } else {
            this.isSmallScreen = false;
        }
    }
}
