<div class="col-12 col pb10 pt20">
    <div class="row col-12" style="min-height: 49px">
        <button id="button-create-edit-header-back" nz-button nzType="link" routerLink="{{ path }}" class="mr-32">
            <i nz-icon nzType="arrow-left"></i>
        </button>

        <!-- <div *ngIf="resource.icon2" class="mr-16 mr-sm-32 emh125">
        <i nz-icon [nzType]="resource.icon2"></i>
    </div> -->

        <div fxLayout="column" fxLayoutAlign="start start">
            <div [ngClass]="isSmallScreen ? 'h5' : 'h2'" *ngIf="headerParams.pageType === 'edit'">
                {{ editHeading$ | async }}
            </div>
            <div [ngClass]="isSmallScreen ? 'h5' : 'h2'" *ngIf="headerParams.pageType === 'new'">
                {{ newHeading | translate }}
            </div>
            <div [ngClass]="isSmallScreen ? 'h6' : 'subtitle secondary-text'">
                <span>{{ newSubHeading | translate }}</span>
            </div>
        </div>
    </div>
    <div class="col-12 row justify-end" style="margin-top: -20px">
        <button
            *ngIf="headerParams.pageType === 'new'"
            id="button-create-edit-header-add"
            nz-button
            nzType="primary"
            [nzLoading]="saving$ | async"
            [disabled]="headerParams.addDisabled$ | async"
            (click)="headerParams.addEntity()">
            <i nz-icon nzType="plus-circle"></i><span>{{ addButtonText | translate }}</span>
        </button>

        <button
            *ngIf="headerParams.pageType === 'edit'"
            id="button-create-edit-header-save"
            nz-button
            nzType="primary"
            [nzLoading]="saving$ | async"
            [disabled]="headerParams.editDisabled$ | async"
            (click)="headerParams.saveEntity()">
            <i nz-icon nzType="plus-circle"></i><span>{{ saveButtonText | translate }}</span>
        </button>

        <button
            *ngIf="headerParams.pageType === 'new' && headerParams.showSaveAndApproveButtonOnAdd() === true"
            id="button-create-edit-header-save-approve"
            class="ml-16"
            nz-button
            [nzLoading]="saveAndApprove$ | async"
            [disabled]="headerParams.editDisabled$ | async"
            (click)="headerParams.addAndApproveEntity()">
            <i nz-icon nzType="save"></i><span>{{ addAndApproveButtonText | translate }}</span>
        </button>

        <button
            *ngIf="headerParams.pageType === 'edit' && headerParams.showSaveAndApproveButtonOnSave() === true"
            id="button-create-edit-header-save-approve"
            class="ml-16"
            nz-button
            [nzLoading]="saveAndApprove$ | async"
            [disabled]="headerParams.editDisabled$ | async"
            (click)="headerParams.saveAndApproveEntity()">
            <i nz-icon nzType="save"></i><span>{{ saveAndApproveButtonText | translate }}</span>
        </button>
    </div>
</div>
