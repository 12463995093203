import {Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {EntityViewHeaderParams} from 'app/blocks/entity/entity-view-header-params';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'di-view-header',
    templateUrl: './di-view-header.component.html',
    styleUrls: ['./di-view-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiViewHeaderComponent implements OnInit, OnDestroy {
    @Input() headerParams: EntityViewHeaderParams;

    private _defaultIcon = 'arrow_back';
    private _headingSubject = new BehaviorSubject<string>('');
    private _unsubscribeAll = new Subject<void>();

    heading$: Observable<string> = this._headingSubject.asObservable();
    subHeadingKey: string;
    editButtonTextKey: string;
    deleteButtonTextKey: string;

    constructor() {}

    ngOnInit(): void {
        if (!this.headerParams.icon) {
            this.headerParams.icon = this._defaultIcon;
        }

        this.headerParams.entity$.pipe(takeUntil(this._unsubscribeAll)).subscribe((entity) => {
            this._headingSubject.next(this.headerParams.resource.getEntityDiscriminator(entity));
        });

        this.subHeadingKey = `${this.headerParams.resource.name}.pages.view.subHeading`;
        this.editButtonTextKey = `${this.headerParams.resource.name}.pages.view.buttons.edit`;
        this.deleteButtonTextKey = `${this.headerParams.resource.name}.pages.view.buttons.delete`;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
