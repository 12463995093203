import {Observable, combineLatest, BehaviorSubject, of} from 'rxjs';
import {Store} from '@ngxs/store';
import {Component, Input, OnInit, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import {EntityListHeaderParams} from 'app/blocks/entity/entity-list-header-params';
import {DiSearchBarComponent} from 'app/common/di-search-bar/di-search-bar.component';
import {ElasticSearchService} from 'app/blocks/service/api/elastic-search.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'app/common/dialog/dialog-service';

@Component({
    selector: 'di-inline-list-page-header',
    templateUrl: './di-inline-list-page-header.component.html',
    styleUrls: ['./di-inline-list-page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiInlineListPageHeaderComponent implements OnInit {
    @Input()
    headerParams: EntityListHeaderParams;

    @ViewChild(DiSearchBarComponent, {static: true})
    public readonly search: DiSearchBarComponent;

    reindexingSubject = new BehaviorSubject<boolean>(false);
    reindexing$ = this.reindexingSubject.asObservable();

    selectedIds$: Observable<number[]>;
    statusUserInput$: Observable<boolean>;
    adding = false;

    headingKey: string;
    addButtonTextKey: string;
    reindexButtonTextKey: string;

    isImportable$: Observable<boolean> = of(false);

    constructor(
        public readonly store: Store,
        private reindexService: ElasticSearchService,
        private translateService: TranslateService,
        private dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.headingKey = `${this.headerParams.resource.name}.pages.list.heading`;
        this.addButtonTextKey = `${this.headerParams.resource.name}.pages.list.buttons.add`;
        this.reindexButtonTextKey = `common.reindex.reindex`;
        this.selectedIds$ = this.store.select((state) => state.entities[this.headerParams.resource.name].listView.selectedIds);
        this.statusUserInput$ = this.store.select((state) => state.entities[this.headerParams.resource.name].listView.statusUserInput);

        combineLatest([
            this.store.select((state) => state.entities[this.headerParams.resource.name].listView.statusSaving),
            this.store.select((state) => state.entities[this.headerParams.resource.name].listView.statusAdding)
        ]).subscribe(([statusSaving, statusAdding]) => {
            this.adding = statusSaving && statusAdding;
        });
        this.isImportable$ = of(this.headerParams.groupActions.map((action) => action.label).includes('importCsv'));
    }

    reindex(): void {
        this.reindexingSubject.next(true);
        this.reindexService
            .postElasticSearchReIndexRequest(this.headerParams.resource.name, true)
            .then((r) => {
                this.reindexingSubject.next(false);
                this.translateService
                    .get('common.reindex.messages.ResultOK.message')
                    .pipe()
                    .subscribe((message: string) => {
                        if (message) {
                            this.dialogService.showMessage({
                                message: message,
                                confirmText: 'common.ok'
                            });
                        }
                    });
            })
            .catch((e) => {
                this.reindexingSubject.next(false);
                this.translateService
                    .get('common.reindex.messages.ResultFailed.message')
                    .pipe()
                    .subscribe((message: string) => {
                        if (message) {
                            this.dialogService.showMessage({
                                message: message,
                                confirmText: 'common.ok'
                            });
                        }
                    });
            });
    }
}
